﻿// JS Dependencies: Popper, Bootstrap & JQuery
import '@popperjs/core';
import 'bootstrap';
// Using the next two lines is like including partial view _ValidationScriptsPartial.cshtml
import 'jquery-validation';
import 'jquery-validation-unobtrusive';

// Import SCSS
import '../sass/site.scss';

// Other imports
import '../css/site.css';
import moment from 'moment';
import DataTable from 'datatables.net-bs5';
import 'datatables.net-plugins/filtering/type-based/accent-neutralise.mjs';
// The only way jQuery works with a combination of Webpack and DataTables :/
window.$ = window.jQuery = require('jquery');

// Globally exported moment object is required for DataTable
window.moment = moment;

// Custom JS
$(document).ready(function () {
    $('#table-students').DataTable({
        scrollX: true,
        stateSave: true,
        columnDefs: [{
            targets: `th-last-submission`,
            render: DataTable.render.datetime('DD.MM.YYYY HH:mm:ss', 'cs')
        }, {
            targets: 'th-task',
            orderable: false
        }]
    });
});